<template>
  <div class="padding-all ">
    <NotificationItem />
  </div>
</template>

<script>
import NotificationItem from '@/views/notifications/components/NotificationItem'

export default {
  name: 'Notifications',
  components: { NotificationItem }
}
</script>
